





























































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation} from 'vuex-class';
import { CompteValidationMixin } from '@/shared/mixins';
import { Adresse, TypeVoie, Ville, AdresseGouv } from '@/models';
import { ApiAutocompleteHelper } from '@/services/ApiAutocompleteHelper';
import { ApiService } from '@/services/base/ApiService';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle, ValeurReferentielle } from '@/shared/models';


@Component({
    name: 'CeeAdresse',
    components: { CeeAutocomplete },
})
export default class CeeAdresse extends Mixins(CompteValidationMixin) {

    /**
     * Pour utiliser v-model au niveau du composant en définissant la propriété 'value' et mise à jour avec l'événement 'input'
     * Voir https://fr.vuejs.org/v2/guide/components.html#Personnalisation-de-composant-avec-v-model
     * */
    @Prop({ default: new Adresse() })
    public value!: Adresse;

    /**
     * Permet de savoir si il faut afficher le complément d'adresse.
     */
    @Prop({ default: false })
    public afficherComplementVoie!: boolean;

    /**
     * Permet de savoir si l'adresse doit être en lecture seule.
     */
    @Prop({ default: false })
    public isReadOnly!: boolean;

    /**
     * Permet de savoir si le numéro et le complément d'adresse sont editable.
     */
    @Prop({ default: false })
    public estPartialementEditable!: boolean;

    /**
     * Permet de savoir si l'adresse doit être désactivée.
     */
    @Prop({ default: false })
    public isDisabled!: boolean;

    @Prop()
    public typesVoieFromParent: TypeVoie[];

    // Les types de voie.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeVoie))
    public typesVoie: TypeVoie[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;
    @Mutation(ReferentielStoreMethods.SET_VALEURS_REFERENTIELLES)
    public setTypesVoie: (payload: { cle: string, valeursReferentielles: ValeurReferentielle[] }) => void;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    /**
     * Permet de savoir si l'utilisateur courant est interne ou non.
     * Afin de gérer l'affichage du mode saisie libre pour le code postal et la ville.
     */
    public estInterne: boolean = false;

    /**
     * Liste des villes récupérés via le code
     */
    public villes: Ville[] = null;

    public adresseId: string = '';

    public adresseComplete: AdresseGouv = null;

    public adresses: AdresseGouv[] = new Array<AdresseGouv>();

    /**
     * Hook qui se déclenche lorsque le composant est créé.
     */
    public created() {
        this.getUserProfile.then((profil) => {
            this.estInterne = profil && profil.isInterne;
        });
        if (!this.typesVoieFromParent) {
            this.getValeursReferentielles(TypeValeurReferentielle.TypeVoie);
        }
    }

    /**
     * Promesse pour l'AutoComplete des villes.
     * @param recherche Saisie du libellé de la ville.
     */
    public villesPromise(recherche: string): Promise<Ville[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<Ville>(recherche, `/ville/obtenirParLibelle`)
    }

    /**
     * Promesse pour l'AutoComplete des villes.
     * @param recherche Saisie du libellé de la ville.
     */
    public adressesPromise(recherche: string): Promise<AdresseGouv[]> {
        return this.$http.get(`/adresse/getAdressesPromise/${recherche}`).then(response => { 
            this.adresses = response.data.data;
            return this.adresses;
        });
    }

    public updateAdresse(): void {
        this.adresseComplete = this.adresses.filter((adresse: AdresseGouv) => adresse.id === this.adresseId)[0];
        this.value.numeroVoie = this.adresseComplete.housenumber;
        this.value.typeVoieId = 99;
        this.value.nomVoie = this.adresseComplete.street;
        this.value.ville.codePostal = this.adresseComplete.postcode;
        this.getVillesByCodePostal();
    }

    /**
     * Promesse pour l'AutoComplete des villes.
     * @param recherche Identifiant de la ville.
     */
    public villesUpdatePromise(): Promise<Ville[]> {
        return new Promise<Ville[]>((resolve) => {
            resolve(this.villes);
        });
    }

    /**
     * Défini la bonne promesse à évaluer.
     * @param villeId Identifiant de la ville.
     */
    public getVillesPromise(villeId: number): Promise<Ville[]> {
        if (this.isReadOnly ||
            this.value.villeId > 0
            && (!this.villes || this.villes.length === 0)) {
            return this.villeSelected(villeId);
        }
        return this.villesUpdatePromise();
    }
    /**
     * Récupération de la ville correspondante à l'id.
     */
    public villeSelected(villeId: number): Promise<Ville[]> {
        return new Promise<Ville[]>((resolve, reject) => {
            ApiAutocompleteHelper.getAutocompletebyIdPromise<Ville>(villeId, `ville/obtenir`)
                .then((villeFound: Ville) => {
                    let villes: Ville[] = [];
                    if (villeFound) {
                        villes.push(villeFound);
                    }
                    resolve(villes);
                });

        });

    }
    /**
     * Validation du format du code postal et Récupérer les villes qui correspondent à celui-ci.
     */
    public getVillesByCodePostal(): void {
        if ((new RegExp('^[0-9]{5}$')).test(this.value.ville.codePostal)) {
            const villeService = new ApiService<Ville>('ville/obtenir');
            villeService.getWhere(`codePostal=${this.value.ville.codePostal}`)
                .then((response) => {
                    const villes = response.data.data as Ville[];
                    this.villes = [];

                    if (villes.length > 1) {
                        this.villes = villes;
                    }
                    const id = villes[0] ? villes[0].id : null;
                    this.$set(this.value, 'villeId', id);

                })
                .catch(() => {
                    throw new Error('Une erreur est survenue');
                });
        }
        this.$emit("changeCodePostal", this.value.ville.codePostal);
    }
    /**
     * Mise à jour du code postal en fonction de la ville sélectionnée.
     * */
    public updateCodePostal(ville: Ville) {
        if (ville) {
            this.value.ville.codePostal = ville.codePostal;
        }
    }

    /**
     * On vide la saisie libre si besoin
     */
    public miseAJourSaisieLibre() {
        if (!this.value.estSaisieLibre) {
            this.value.codePostalLibre = null;
            this.value.villeLibre = null;
        }
    }

    /**
     * Réinitialisation de la liste des villes.
     */
    @Watch('value')
    public resetListeVilles() {
        this.villes = [];
    }
}
